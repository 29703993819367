import React, { Component } from 'react';


interface Props{
    index: number,
    title: string,
    image: string,
    url: string
}

class BoxArticle extends Component<Props>{

    click = (url) => {
        if(url != "")
            window.open(url);
    }

    render(){

        const {index, title, image, url} = this.props;


        return (
            <div className={["col ", index === 4 || index === 5 ? "m8" : "m4"].join(" ")} onClick={() => this.click(url)}>
                <div className="portfolio-esp" style={image ? {background: "url("+ image +")", backgroundSize: 'cover', backgroundPosition: 'center'}: {}}>
                    <a className="infoCard">{title}</a>
                </div>
            </div>
        );
    }
}

export default BoxArticle;
import React from 'react';
import ReactDOM from 'react-dom';
import { setDefaultTranslations, setDefaultLanguage, getLanguage, setLanguage } from 'react-multi-lang'
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import PublicRoute from './components/PublicRoute';
import Index from './screens/Index/Index';
import * as serviceWorker from './serviceWorker';
import it from './translations/it.json';
import './assets/css/style.css';

/**
 * Set Language
 */
setDefaultTranslations({it});
setDefaultLanguage('it');

/**
 * Get language cookie sected
 */


if(localStorage.getItem('lang') === null || localStorage.getItem('lang') === undefined){

}else{
    if(localStorage.getItem('lang') === 'it'){
        setLanguage(localStorage.getItem('lang'));
    }else{
        localStorage.set('lang', getLanguage());
    }
} 

/**
 * Version EthernialJS
 */
console.log("%cEspoweb version 0.1.55", "color: #fefefe; font-size: 1.3rem;");

/**
 * Routes pages
 */

const RouteApp = (
    <Router>
        <Switch>
            <PublicRoute component={Index} restricted={false} path="/" exact />
            <PublicRoute restricted={false} path="*" component={Index} />
        </Switch>
    </Router>
);

ReactDOM.render(RouteApp, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

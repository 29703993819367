import React, {Component} from 'react';
import styles from './style.module.css';
import Hex from '../../assets/image/test.png';
import Avatar from '../../assets/image/avatar.svg';

interface Props{
    scroller: string,
    closeMenu: boolean
}

interface State{
    openMenu: boolean,
    openMenu2: boolean,
    opMenu: boolean,
    opMenu2: boolean
}

class Header extends Component<Props, State>{

    State: State;
    
    constructor(props: Props){
        super(props);

        this.state = {
            openMenu: false,
            openMenu2: false,
            opMenu: false,
            opMenu2: false
        }
    }

    componentDidUpdate(){
        if(this.props.scroller == "open"){
            this.state.openMenu = (this.props.scroller == "open" ? false : true)
        }
        if(this.props.closeMenu === true){
            this.state.openMenu2 = false;
        }
    }

    openMenu = (e) => {
        e.preventDefault();
        
        this.setState({
            openMenu: !this.state.openMenu,
            opMenu: !this.state.opMenu
        }); 
    }

    openMenu2 = (e) => {
        e.preventDefault();

        this.setState({
            openMenu2: !this.state.openMenu2,
            opMenu2: !this.state.opMenu2,
        }); 
    }

    render(){ 
        
        return (
            <>
                <header className="header">
                    <nav className={["transparent reset-this fixed-nav", this.state.openMenu2 && this.state.opMenu2 ? "white open_menu" : ""].join(" ")}>
                        <div className={["nvb-wrapper container-re"].join(" ")} >
                            <a className="navbar-logo text-bolder" href="#"><span className="deepPurle-text"></span>espoweb<span className="deepPurle-text "></span></a>
                            <a onClick={this.openMenu2} className="only-mobile menu-esp" style={{position: 'absolute',top: 18}}><span className="material-icons">menu</span></a>
                            <ul className="no-style right desktop-mode" style={this.state.openMenu2 && this.state.opMenu2 ? {display: 'block'} : {}}>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#meinfo">Su di me</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#skills">Skills</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#portfolio">Portfolio</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#contact">Contatti</a></li>
                            </ul>
                        </div>
                    </nav>

                    <nav className={["transparent reset-this scrollable", this.state.openMenu ? "open_moby_menu fixed" : ""].join(" ")}>
                        <div className={["nvb-wrapper container-re"].join(" ")}>
                            <a className="navbar-logo text-bolder" href="#"><span className="deepPurle-text"></span>espoweb<span className="deepPurle-text "></span></a>
                            <a onClick={this.openMenu} className="only-mobile menu-esp"><span className="material-icons">menu</span></a>
                            <ul className=" right pst-esp" style={this.state.openMenu && this.state.opMenu ? {display: 'block'} : {}}>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#meinfo">Su di me</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#skills">Skills</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#portfolio">Portfolio</a></li>
                                <li><a className={["btn btn-flat deepPurle dark-text btn-small", "textBolder"].join(" ")} href="#contact">Contatti</a></li>
                            </ul>
                        </div>
                    </nav>

                    <div className="l_title_esp">
                        <h1 className="text-bolder text-uppercase header-h1">
                            <span className="material-icons star-icon">grade</span>
                            Benvenuto da<br />
                            <span className="deepPurle-text">espoweb</span><br/>
                            apassionati allo <br/>
                            <span className="material-icons octogon-icon">report</span>
                            sviluppo web
                        </h1>
                    </div>

                    <div className="hexagon"></div>
                    <div className="triangle"></div>

                </header>
            </>
        );
    }
}

export default Header;
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Index';
import Hex from '../../assets/image/test.png';
import Avatar from '../../assets/image/avatar.svg';
import Table from '../../assets/image/mesetdown.svg';
import Assistent from '../../assets/image/assistent.svg';
import $ from 'jquery'; 
import BoxArticle from '../../components/Articles/Index';
import GridImage from '../../assets/image/portfolio/grid.png';
import AlertImage from '../../assets/image/portfolio/alert.png';
import MaterialImage from '../../assets/image/portfolio/material.png';
import JFastImage from '../../assets/image/portfolio/jfast.png';
import GaiaImage from '../../assets/image/portfolio/gaia.png';
import SoftMaterialImage from '../../assets/image/portfolio/softMaterial.png';
import ForumImage from '../../assets/image/portfolio/forum.png';
import PromotoImage from '../../assets/image/portfolio/promoto.png';
import EthernialImage from '../../assets/image/portfolio/ethernial.png';

interface Props{

}

interface State{
    headerRemove: string,
    closeMenu: boolean,
    portfolio: Object,
    filterButton: string,
    loadingPage: boolean
}

class Index extends Component<Props, State>{

    state: State;

    constructor(props: Props){
        super(props);

        this.state = {
            headerRemove: 'closed',
            closeMenu: false,
            filterButton: null,
            loadingPage: false,
            portfolio: [
                {
                    title: "Promoto",
                    image: PromotoImage,
                    url: "https://promo-to.it",
                    filter: ['php', 'js', 'react', 'ios', 'htmlcss']
                },
                {
                    title: "Forum",
                    image: ForumImage,
                    url: "https://forumbb.altervista.org/",
                    filter: ['php', 'js', 'html', 'htmlcss']
                },
                {
                    title: "System Grid Framework",
                    image: GridImage,
                    url: "https://espoweb.it/demo/gridSystem/",
                    filter: ['css', 'js', 'html', 'htmlcss']
                },
                {
                    title: "Creating Image",
                    image: "https://espoweb.it/demo/imgCreate/?txt=Click+Here&size=40&w=800&h=500",
                    url: "https://espoweb.it/demo/imgCreate/?txt=Click+Here&size=40&w=800&h=500",
                    filter: ['php']
                },
                {
                    title: "SoftMaterial 1.2.1",
                    image: SoftMaterialImage,
                    url: "https://espoweb.it/material/#!/",
                    filter: ['js', 'html', 'css', 'htmlcss']
                },
                {
                    title: "Ethernial",
                    image: EthernialImage,
                    url: "https://ethernial.com",
                    filter: ['php', 'js', 'html', 'css', 'react', 'c#', 'htmlcss']
                },
                {
                    title: "PaffAlert (Popup)",
                    image: AlertImage,
                    url: "https://espoweb.it/demo/PaffAlert/",
                    filter: ['js', 'html', 'css', 'htmlcss']
                },
                {
                    title: "uiMaterial 0.5",
                    image: MaterialImage,
                    url: "https://espoweb.it/demo/uiMaterial/",
                    filter: ['css', 'js', 'html', 'htmlcss']
                },
                {
                    title: "JFast",
                    image: JFastImage,
                    url: "https://espoweb.it/demo/JFast/jFast.min.js",
                    filter: ['js']
                },
                {
                    title: "Gaia Framework PHP V1.5",
                    image: GaiaImage,
                    url: "https://github.com/frankin0/Gaia",
                    filter: ['php', 'js', 'html', 'htmlcss']
                }
            ]
        }
    }


    componentDidMount(){
        $('a[href].link, nav ul li a[href]').on('click', function(e){
            if(document.getElementById(e.target.hash.replace('#', ''))){
                var v = 0;
                
                $('html, body').animate({ 
                    scrollTop: $(e.target.hash).offset().top + (v),
                }, 'slow');
                window.location.hash = e.target.hash.replace('#', '');
            }
            e.preventDefault();
        });

        window.addEventListener('scroll', this.handleScroll);

        if(window.location.hash) {
            if(document.getElementById(window.location.hash.replace('#', ''))){
                $('html, body').animate({
                   scrollTop: $(window.location.hash).offset().top
                }, 'slow');
            }
        }
       
        const checkBrand = document.getElementsByClassName('softMaterial--brand');
        let interval = setInterval(() => {
            if(checkBrand[0]){
                this.setState({
                    loadingPage: true
                });
                clearInterval(interval);
            }
        }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    
    handleScroll = (event) => {
        let scrollTop = event.srcElement.body.scrollTop,
        itemTranslate = Math.min(0, scrollTop/3 - 60);

        if(window.scrollY >= 700 && window.scrollY <= document.getElementById('nav-color').offsetTop - 300){
            document.getElementsByClassName('scrollable')[0].classList.add('fixed');
            document.getElementsByClassName('fixed-nav')[0].classList.remove('white', 'open_menu');
            document.getElementsByClassName('desktop-mode')[0].style.display = "none";
            this.setState({
                closeMenu: true
            });
        }else if(window.scrollY >= document.getElementById('nav-color').offsetTop - 300){
            document.getElementsByClassName('scrollable')[0].classList.remove('fixed');
            this.setState({
                headerRemove: 'open'
            });
        }else{
            document.getElementsByClassName('scrollable')[0].classList.remove('fixed');
            this.setState({
                headerRemove: 'open'
            });
        }
    }

    filterButton = (filter) =>{
        this.setState({
            filterButton: filter
        });
    }
    

    render(){ 
        


        return(
            <>
                <Helmet>
                    <title>Espoweb</title>
                    {/*<!--Import Google Icon Font-->*/}
                    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                    {/*<link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" />*/}
                    <link href="https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600&display=swap" rel="stylesheet" />

                    <meta charSet="utf-8" />
                    
                    <meta itemProp="image" content="https://espoweb.it/img/logo.png" />
                    <meta name="author" content="espoweb.it - Francesco Esposito" />
                    <meta name="description" content="Espoweb progetta, crea e organizza le tue pagine web, i tuoi social e i tuoi forum ottimizzati per Smartphone, tablet e Desktop. Vieni a trovarci!!"/>
                    <meta name="keywords" content="creazione,siti,web,HTML,CSS,JavaScript,gestione social,social,copy,copywriting,francesco esposito"/>
                    <meta name="theme-color" content="#fff"/>
                    
                    <meta property="og:title" content="espoweb.it" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://espoweb.it" />
                    <meta property="og:site_name" content="espoweb.it" />
                    <meta property="og:description" content="Espoweb progetta, crea e organizza le tue pagine web, i tuoi social e i tuoi forum ottimizzati per Smartphone, tablet e Desktop. Vieni a trovarci!!" />
                    <meta property="og:image" content="https://espoweb.it/img/logo.png"/>
                    
                    {/*<!--Let browser know website is optimized for mobile-->*/}
                    <meta name="viewport" content="width=device-width, user-scalable=no,initial-scale=1, minimum-scale=1, maximum-scale=1" />
                    <meta name="HandheldFriendly" content="True" />
                    <meta name="apple-touch-fullscreen" content="yes" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    
                    <link href="img/logo.png" rel="apple-touch-icon" />
                    <link href="img/logo.png" rel="shortcut icon" />
                    <link href="/favicon.ico" rel="icon" />	
                    <link rel="icon" type="image/png" sizes="64x64" href="img/logo.png" />
                    <link rel="apple-touch-icon" sizes="180x180" href="img/logo.png" />
                    

                    <link rel="stylesheet" href="https://material.espoweb.it/dev/SoftMaterial.css" />

                    {/*<!--Import jQuery before SoftMaterial.js-->*/}
                    <script type="text/javascript" src="https://code.jquery.com/jquery-2.1.1.min.js" ></script>
                    <script src="https://material.espoweb.it/stable/SoftMaterial.min2.js"></script>

                    <script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                    <script type="text/javascript" src="https://www.google-analytics.com/gtm/js?id=GTM-PZWHVVD&cid=1612765012.1590159993"></script><script async="" src="https://www.google-analytics.com/analytics.js"></script>

                    <script type="text/javascript">{`
                        var _iub = _iub || [];
                        _iub.csConfiguration = {
                            "consentOnContinuedBrowsing":false,
                            "whitelabel":false,
                            "lang":"it",
                            "siteId":2017804,
                            "cookiePolicyId":54680395,
                            "enableCMP":true, 
                            "banner":{ 
                                "acceptButtonDisplay":true,
                                "customizeButtonDisplay":true,
                                "position":"float-top-center",
                                "acceptButtonColor":"#0073CE",
                                "acceptButtonCaptionColor":"white",
                                "customizeButtonColor":"#DADADA",
                                "customizeButtonCaptionColor":"#4D4D4D",
                                "rejectButtonColor":"#0073CE",
                                "rejectButtonCaptionColor":"white",
                                "textColor":"black",
                                "backgroundColor":"white" 
                            }
                        };

                        (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                        h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                        (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                        })(window,document.documentElement,'async-hide','dataLayer',4000,
                        {'GTM-PZWHVVD':true});
                    
                        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                        ga('create', 'UA-66216114-3', 'auto');
                        ga('require', 'GTM-PZWHVVD');
                        ga('send', 'pageview');
                    
                        (adsbygoogle = window.adsbygoogle || []).push({
                            google_ad_client: "ca-pub-2089277849185644",
                            enable_page_level_ads: true
                        });
                        `}
                    </script>

                    <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/beta/stub-v2.js"></script>
                    <script type="text/javascript" src="//cdn.iubenda.com/cs/beta/iubenda_cs.js" async></script>

                </Helmet>

                <div className={["loading", this.state.loadingPage ? "hidden" : ""].join(" ")}></div>

                <Header scroller={this.state.headerRemove} closeMenu={this.state.closeMenu}/>

                <section className="section_1">
                    <p className="text-center t1_esp">Ti piace la UI Material? Sei nel posto giusto! Qui sotto potrai trovare tutti i<br /> componenti e le informazioni da poter utilizzare sul tuo progetto.</p>
                    <div className="img_bk_esp"></div>
                    <div>
                        <a href="" className="link_scroll_esp" style={{zIndex: 1}}><span className="material-icons no-style-font">keyboard_backspace</span></a>
                    </div>
                </section>

                <section className="sect3" id="meinfo">
                    <div className="container right-text">
                        <div className="col m8 s8"> 
                            <div className="text-right port_as2">
                                <span>Salve <span className="line-res_espo"></span></span>
                                <h2>Mi chiamo Francesco</h2>
                                <p>Diplomato in Tecnico Dei Sistemi Energetici, per passione al quinto anno, ho deciso di investire tempo libero in quel che è il mio hobby nella tecnologia ed informtica da autodidatta e frequentando corsi con certificato sul portale <u>Accademia domani</u> per la programmazione web. Nel tempo, ho lavorato come Freelancer accrescendo il mio bagaglio d'esperienza con l'uscita di nuove tecnologie come "<b>Angularjs</b>, <b>React</b>, <b>Laravel</b>, <b>Bootstrap</b>".</p>
                                <p>Tenendo sempre in considerazione, in base al progetto creato, il tipo di teconologia da utilizzare, ho lavorato con il cms <b>Wordpress</b>, <b>Drupal</b> e forum come <b>vBulettin</b> e <b>phpBB</b>.</p>
                                <p>In questa landing troverete alcuni miei lavori svolti nel tempo.</p>
                            
                            </div>
                            <div className="col m12 port_as3">
                                <div className="col m3 s3 xs4 asd__">
                                    <p>
                                        Italia <i className="material-icons">location_on</i>
                                    </p>
                                </div>
                                <div className="col m4 s4 xs8">
                                    <p>
                                        Freelancer<i className="material-icons">code</i>
                                    </p>
                                </div>
                                <div className="col m5 s5 xs12 no-mobile">
                                    <p>
                                        Coming Soon<i className="material-icons">phone_enabled</i>
                                    </p>
                                </div>
                            </div>
                            <div className="col m12 port_as3">
                                <div className="col m3 s2 xs12">
                                 <p>&nbsp;</p>
                                </div>
                                <div className="col m4 s4 xs4 asd__">
                                    <p>
                                        @espoweb<i className="material-icons">person_outline</i>
                                    </p>
                                </div>
                                <div className="col m5 s6 xs8">
                                    <p>
                                        Francescoe15@gmail.com<i className="material-icons">email</i>
                                    </p>
                                </div>
                            </div>

                            <a className="btn btn-shadow deepPurle right btn-round" style={{marginRight: 20}} href="#contact">Contattami</a>
                        </div>
                        <div className="col m4 s4">
                            <img src={Avatar} width="100%" className="myAvatar-esp"/>
                        </div>
                    </div>
                </section>

                <section className="sect4" id="skills">
                    <div className="sign-circular"></div>
                    <div className="sign-cross"></div>
                    <div className="sign-sdf4"></div>
                    
                    <div style={{position: "absolute", bottom: 300, left: 100}}>
                        <div className="arrow-right" style={{transform: "scale(1.3) rotate(-48deg) translate(100px, 95px)", width: 174, height: 122, borderTop: "85px solid transparent"}}></div>
                        <div className="arrow-right" style={{transform: "scale(1.3) rotate(-117deg) translate(-3px, 105px)", width: 174, height: 122, borderTop: "85px solid transparent",borderLeft: "50px solid #54b4d69e"}}></div>
                    </div>


                    <div className="container">
                        <div className="ps_sct4_esp">
                            <div className="sub-title-esp">
                                <span className="line-res_esp"></span>
                                <span>Le mie abilità</span>
                            </div>
                            <h2>Skills</h2>
                            <div className="col m12">
                                <div className="col m4 s6 crd-cont-esp">
                                    <span>php</span>
                                    <b>PHP Developer</b>
                                    <p>PHP è un linguaggio di scripting interpretato, originariamente concepito per la programmazione di pagine web dinamiche.</p>
                                </div>
                                <div className="col m4 s6 crd-cont-esp">
                                    <span>HTML5, CSS3</span>
                                    <b>HTML & CSS</b>
                                    <p>Strutture in Html5/CSS3 per lo sviluppo di una interfaccia grafica lato web client.</p>
                                </div>
                                <div className="col m4 s12 crd-cont-esp">
                                    <span>Js</span>
                                    <b>Javascript / Typescript & Nodejs Developer</b>
                                    <p>In informatica JavaScript è un linguaggio di scripting orientato agli oggetti e agli eventi, comunemente utilizzato nella programmazione Web lato client per la creazione, in siti web e applicazioni.</p>
                                </div>
                            </div>
                            <div className="col m12">
                                <div className="col m4 s6 crd-cont-esp">
                                    <span>DB</span>
                                    <b>Database</b>
                                    <p>Progettazioni tabbelle relazionali per database SQL & progettazione e gestione database NoSql.</p>
                                </div>
                                <div className="col m4 s6 crd-cont-esp">
                                    <span>SV</span>
                                    <b>Servers</b>
                                    <p>Un server in informatica e telecomunicazioni è un componente o sottosistema informatico di elaborazione e gestione del traffico di informazioni che fornisce, a livello logico e fisico.</p>
                                </div>
                                <div className="col m4 s12 crd-cont-esp">
                                    <span>CMS</span>
                                    <b>Wordpress</b>
                                    <p>Wordpress uno dei CMS più famosi del web, veloce, affidabile e al passo con aggiornamenti per migliorare la stabilità dell'intero sistema.</p>
                                </div>
                            </div>
                            <div className="col m12">
                                <div className="offset-m4 col m8" style={{zIndex: 1, position: 'relative'}}>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">php</b></span>
                                        <div className="progressBar">
                                            <div className="progress primary" style={{width: "80%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %80</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">HTML / CSS3</b></span>
                                        <div className="progressBar">
                                            <div className="progress purple" style={{width: "100%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %100</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">Js</b></span>
                                        <div className="progressBar">
                                            <div className="progress pink" style={{width: "99%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %99</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">Typescript</b></span>
                                        <div className="progressBar">
                                            <div className="progress deepPurle" style={{width: "60%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %60</span>
                                    </div>

                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">NodeJS</b></span>
                                        <div className="progressBar">
                                            <div className="progress indigo" style={{width: "69%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %69</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">Database & NoSQL</b></span>
                                        <div className="progressBar">
                                            <div className="progress blue" style={{width: "80%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %80</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">Wordpress</b></span>
                                        <div className="progressBar">
                                            <div className="progress cyan" style={{width: "100%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %100</span>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">ReactJS</b></span>
                                        <div className="progressBar">
                                            <div className="progress teal" style={{width: "100%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %100</span>
                                    </div>

                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">React Native</b></span>
                                        <div className="progressBar">
                                            <div className="progress teal" style={{width: "44%"}}></div>
                                        </div>
                                        <span className="progress-percentage"> %44</span>
                                    </div>

                                    <div style={{display: 'flex'}}>
                                        <span><b className="label-bar-esp">Angular</b></span>
                                        <div className="progressBar">
                                            <div className="progress green" style={{width: "55%"}}></div>
                                        </div>
                                        <span className="progress-percentage">%55</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sect5" id="portfolio">
                    <div className="arrow-right" style={{transform: "scale(.9) rotate(20deg) translate(100px, 95px)"}}></div>
                    <div className="arrow-right" style={{transform: "scale(.5) rotate(-35deg)", left: 250, top: 800}}></div>

                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", left: 250, bottom: "30%"}}></div>
                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", left: 220, bottom: "31%"}}></div>
                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", left: 190, bottom: "32%"}}></div>
                    
                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", right: 250, bottom: "50%"}}></div>
                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", right: 220, bottom: "50%"}}></div>
                    <div className="diamond" style={{transform: "scale(.3) rotate(0deg)", right: 190, bottom: "50%"}}></div>

                    <div className="triangle-line" style={{ transform: "scale(1) rotate(60deg)", right: 0, top: 100, width: 174,height: 122,paddingBottom: "3.50%"}}></div>
                    <div className="triangle-line2" style={{ transform: "scale(1) rotate(-60deg)", right: 100, top: 200, width: 174,height: 122,paddingBottom: "3.50%"}}></div>
                    <div className="triangle-line" style={{ transform: "scale(1) rotate(-168deg)", right: 6, top: 286, width: 174,height: 122,paddingBottom: "3.50%"}}></div>

                    <div className="arrow-right" style={{transform: "scale(1) rotate(180deg)", right: 250, bottom: 200, borderLeftColor: "#85d6f1"}}></div>

                    <div className="container">
                        <div className="ps_sct4_esp">
                            <div className="sub-title-esp">
                                <span className="line-res_esp"></span>
                                <span>My project</span>
                            </div>
                            <h2>Portfolio</h2>
                            <div className="col m12" style={{textAlign: 'center',zIndex: 1,position: 'relative'}}>
                                <ul style={{display: "inline-flex"}}>
                                    <li><a href="" onClick={() => this.filterButton(null)} className={["", this.state.filterButton == null ? "lightBlue-text active" : ""].join(" ")}>Tutto</a></li>
                                    <li><a href="" onClick={() => this.filterButton("php")} className={["", this.state.filterButton == "php" ? "active" : ""].join(" ")}>Php & Server</a></li>
                                    <li><a href="" onClick={() => this.filterButton("htmlcss")} className={["", this.state.filterButton == "htmlcss" ? "active" : ""].join(" ")}>HTML / CSS</a></li>
                                    <li><a href="" onClick={() => this.filterButton("js")} className={["", this.state.filterButton == "js" ? "active" : ""].join(" ")}>JS</a></li>
                                </ul>
                            </div>
                            <div className="col m12" style={{marginTop: 0, zIndex: 1, position: 'relative'}}>
                                {
                                    this.state.portfolio.map((element, index) => {

                                        if(element.filter.indexOf(this.state.filterButton ) >= 0 || this.state.filterButton == null) 
                                            return <BoxArticle key={index} index={index} title={element.title} image={element.image} url={element.url} /> ;
                                    })
                                }

                                {/*<div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>

                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                <div className="col m8">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                
                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                                </div>
                                <div className="col m4">
                                    <div className="portfolio-esp">
                                        <a className="infoCard">Test ##</a>
                                    </div>
                    </div>*/}
                            </div>
                        </div>
                    </div>
                </section>

{/*
                <section className="sect6" style={{minHeight: "auto"}}>
                    <div className="container">
                        <div className="ps_sct4_esp">
                            <div className="sub-title-esp">
                                <span className="line-res_esp"></span>
                                <span>My Clients Works</span>
                            </div>
                            <h2>Clients</h2>
                            <div className="col m12 text-center"  style={{margin: 50}}>
                                <p className="text-p">Donec pulvinar feugiat dui vel egastas. Nulla sit amet risus risus. Nulla auctor libero auctor libero vitae erat posuere, sit amet<br /> viverra turpis lobortis. Nullam eleifend cursus tellus a egestas. Donec at elementum orci.</p> 
                            </div>
                        </div>
                    </div>
                </section>*/}

                <section className="contact" id="contact">
                    <div className="container">
                        <div className="ps_sct4_esp text-center" style={{zIndex: 1, position: 'relative'}}>
                            <h2 className="title-contact-esp">Creiamo qualcosa di<br />straordinario!</h2>
                            <div className="col m12 text-center"  style={{margin: "50px 0"}}>
                                <p>Non importa quanto sia grande il tuo prodotto<br /> ma quanto affascina il tuo pubblico.<br /> Che aspetti, contattami!</p> 
                            </div>

                            <div className="col m6 text-left" style={{marginTop: 0}}>
                                <div className="form-box purple-focus" >
                                    <input type="text" className="input-material" />
                                    <label className="placeholder-label">Nome</label> 
                                </div>
                            </div>
                            <div className="col m6 text-left" style={{marginTop: 0}}>
                                <div className="form-box purple-focus" >
                                    <input type="email" className="input-material" required />
                                    <label className="placeholder-label">Email</label> 
                                </div>
                            </div>
                            <div className="col m12 text-left" style={{marginTop: 0}}>
                                <div className="form-box yellow-focus" >
                                    <textarea className="input-material" style={{minHeight: 100}} data-maxcharacters="150" data-autoresize></textarea>
                                    <label className="placeholder-label">Messaggio</label>
                                    <span className="characters_count">0 / 150</span>
                                </div>
                            </div>

                            <div className="col m12 text-left" style={{marginTop: 0}}>
                                <div className="float-left left"><small>oppure su <a href="mailto:francescoe15@gmail.com" className="dark-text">francescoe15@gmail.com</a></small></div>
                                <button className="btn btn-shadow btn-round btn-large right deepPurle" disabled>Invia</button>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="col m12 base-line-esp" id="nav-color">
                    <div style={{position: 'absolute'}} className="footer-image-esp">
                        <img src={Table} style={{transform: "translate(0px, -450px)"}} alt=""/>
                        <img src={Assistent} className="assistent-esp" alt="" style={{}} />
                    </div>
                </div>

                <footer className="col m12">
                    <div className="container nvb-wrapper" style={{margin: "40px auto"}}>
                        <div className="row">
                            <div className="col m4 s12">
                                <h3 className="white-text">espoweb</h3>
                                <a href="mailto:francescoe15@gmail.com" className="white-text">francescoe15@gmail.com</a>
                                <p><a href="./old-version.html" className="white-text">espoweb old version</a></p>
                            </div>
                            
                            <div className="col m3 s4 xs6">
                                <h3 className="green-text text-uppercase " style={{fontSize: "1.2rem",fontWeight: 700, letterSpacing: 2}}>Map</h3>
                                <ul>
                                    <li style={{marginLeft: 0}}><a href="#meinfo" className="link" style={{fontSize: "1rem"}}>Su di me</a></li>
                                    <li style={{marginLeft: 0}}><a href="#skills" className="link" style={{fontSize: "1rem"}}>Skills</a></li>
                                    <li style={{marginLeft: 0}}><a href="#portfolio" className="link" style={{fontSize: "1rem"}}>Portfolio</a></li>
                                    <li style={{marginLeft: 0}}><a href="#contact" className="link" style={{fontSize: "1rem"}}>Contatti</a></li>
                                </ul>
                            </div>
                            <div className="col m3 s4 xs6">
                                <h3 className="green-text text-uppercase " style={{fontSize: "1.2rem",fontWeight: 700, letterSpacing: 2}}>Utili</h3>
                                <ul>
                                    <li style={{marginLeft: 0}}><a href="https://www.iubenda.com/privacy-policy/54680395" target="_blank" style={{fontSize: "1rem"}}>Privacy Policy</a></li>
                                    <li style={{marginLeft: 0}}><a href="https://www.iubenda.com/privacy-policy/54680395/cookie-policy?an=no&s_ck=false&newmarkup=yes" target="_blank" style={{fontSize: "1rem"}}>Cookie Policy</a></li>
                                    <li style={{marginLeft: 0}}><a href="https://github.com/frankin0" target="_blank" style={{fontSize: "1rem"}}>GitHub</a></li>
                                    <li style={{marginLeft: 0}}><a href="https://espoweb.it/material/#!/" target="_blank" style={{fontSize: "1rem"}}>SoftMaterial</a></li>
                                </ul>
                            </div>
                            <div className="col m2 s4">
                                <h3 className="green-text text-uppercase " style={{fontSize: "1.2rem",fontWeight: 700, letterSpacing: 2}}>Social</h3>
                                <ul>
                                    <li style={{marginLeft: 0}}><a href="https://www.facebook.com/espowebcom" style={{fontSize: "1rem"}}>Facebook</a></li>
                                    <li style={{marginLeft: 0}}><a href="https://www.instagram.com/espoweb/?hl=it" style={{fontSize: "1rem"}}>Instagram</a></li>
                                    <li style={{marginLeft: 0}}><a href="https://github.com/frankin0" target="_blank" style={{fontSize: "1rem"}}>GitHub</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container text-center" style={{ fontSize: "1rem", borderTop: "2px solid #23418f", padding: 20}}>
                            © 2018 Copyright espoweb.it
                        </div>
                    </div>
                </footer>

            </>
        );
    }

}

export default Index;